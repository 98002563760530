.summary-cards {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    max-width: 100%;
    margin-bottom:24px;
}

/*reponsiveness*/
@media (max-width: 800px) {
    .summary-cards {
        flex-wrap:wrap;
        gap:16px;
    }
}
