.blueFocusInput {
    width: 100%;
    padding: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin-bottom: 16px;
    border: 1.5px solid #D3D3D3; /* Default gray border */
    border-radius: 4px;
}

.blueFocusInput:focus {
    outline: none; /* Remove default browser outline */
    border: 1.5px solid #000080; /* Add a thicker blue border on focus */
}
